import { FieldArray, useFormikContext } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { PiPlusSquare, PiTrash } from "react-icons/pi";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

import FormikComponentCategoryField from "../../../components/FormikComponentCategoryField/FormikComponentCategoryField";
import countryCodes from "../../../data/country-codes.json";
import { bomIdSelector } from "../../../features/bomWizard/bomWizardSlice";
import useBom from "../../../hooks/useBom";
import { componentAlreadySubmitted } from "../../../utils/bom";
import ComponentField from "./ComponentField";
import ExtendedCost from "./ExtendedCost";
import HTSField from "./HTSField";
import OriginatingRadioGroup from "./OriginatingRadioGroup";

const initialComponent = {
	partNumber: "",
	hts: "",
	description: "",
	category: "Material",
	qty: 1,
	units: "pieces",
	cost: 0,
	origin: "US",
	originating: "N",
};

function OriginField({ componentIdx, bom }) {
	const [currentOrigin, setCurrentOrigin] = useState(null);
	const { values, setFieldValue } = useFormikContext();

	useEffect(() => {
		if (
			values &&
			values.components &&
			values.components[componentIdx] &&
			values.components[componentIdx].origin
		) {
			setCurrentOrigin(values.components[componentIdx].origin);
		} else {
			setCurrentOrigin(null);
		}

		return () => {
			setCurrentOrigin(null);
		};
	}, [values, componentIdx]);

	useEffect(() => {
		if (currentOrigin && currentOrigin.match(/US|MX|CA/)) {
			setFieldValue(`components.${componentIdx}.originating`, "Y");
		} else {
			setFieldValue(`components.${componentIdx}.originating`, "N");
		}
	}, [currentOrigin, setFieldValue, componentIdx]);
	return (
		<ComponentField
			name="origin"
			id={`selectOrigin${componentIdx}`}
			label="Country of Origin"
			formClass="form-select"
			idx={componentIdx}
			as="select"
			disabled={componentAlreadySubmitted(bom && bom.Components, componentIdx)}
		>
			<option value="">Unspecified</option>
			{countryCodes.map((countryCode) => (
				<option key={countryCode} value={countryCode}>
					{countryCode}
				</option>
			))}
		</ComponentField>
	);
}

function ComponentsFieldArray() {
	const { values } = useFormikContext();
	const [hasComponents, setHasComponents] = useState(false);
	const bomId = useSelector(bomIdSelector);
	const { bom } = useBom(bomId);

	const handleAdd = useCallback((arrayHelpers) => {
		arrayHelpers.push(initialComponent);
	}, []);

	const handleDelete = useCallback(
		(arrayHelpers, idx) => {
			if (componentAlreadySubmitted(bom && bom.Components, idx)) {
				Swal.fire({
					title: "Error!",
					text: "Can't delete an already submitted component.",
					icon: "error",
				});
			} else {
				arrayHelpers.remove(idx);
			}
		},
		[bom],
	);

	useEffect(() => {
		if (
			values.components &&
			Array.isArray(values.components) &&
			values.components.length > 0
		) {
			setHasComponents(true);
		} else {
			setHasComponents(false);
		}

		return () => setHasComponents(false);
	}, [values.components]);
	return (
		<FieldArray name="components">
			{(arrayHelpers) => (
				<div>
					{hasComponents &&
						values.components.map((_, idx) => (
							// eslint-disable-next-line react/no-array-index-key
							<div
								className="border border-light-3 rounded p-3 mb-3"
								key={`component-${idx}`}
							>
								<div className="d-flex justify-content-between mb-4">
									<div className="d-flex align-items-center gap-2">
										<h4 className="m-0">Component {idx + 1} Form</h4>
									</div>

									<button
										onClick={() => handleDelete(arrayHelpers, idx)}
										type="button"
										className="btn p-0 no-border-no-outline"
										aria-label="Delete"
									>
										<PiTrash />
									</button>
								</div>

								<div className="mb-3">
									<ComponentField
										label="Part Number"
										name="partNumber"
										id={`inputPartNumber${idx}`}
										idx={idx}
										placeholder="Ex. ABCD1234"
										disabled={componentAlreadySubmitted(
											bom && bom.Components,
											idx,
										)}
									/>
								</div>

								<div className="mb-3">
									<FormikComponentCategoryField
										name={`components.${idx}.category`}
										disabled={componentAlreadySubmitted(
											bom && bom.Components,
											idx,
										)}
									/>
								</div>

								<div className="mb-3">
									<HTSField
										idx={idx}
										disabled={componentAlreadySubmitted(
											bom && bom.Components,
											idx,
										)}
									/>
								</div>

								<div className="mb-3">
									<ComponentField
										label="Description"
										name="description"
										id={`inputDescription${idx}`}
										idx={idx}
										placeholder="Describe the component"
										disabled={componentAlreadySubmitted(
											bom && bom.Components,
											idx,
										)}
									/>
								</div>

								<div className="mb-3">
									<ComponentField
										label="Quantity"
										name="qty"
										id={`inputQty${idx}`}
										idx={idx}
										placeholder="The number of units"
										type="number"
										disabled={componentAlreadySubmitted(
											bom && bom.Components,
											idx,
										)}
									/>
								</div>

								<div className="mb-3">
									<ComponentField
										name="units"
										id={`inputUnits${idx}`}
										label="Measurement Units"
										idx={idx}
										placeholder="Ex. pieces"
										disabled={componentAlreadySubmitted(
											bom && bom.Components,
											idx,
										)}
									/>
								</div>

								<div className="mb-3">
									<div className="d-flex gap-2">
										<div className="flex-grow-1">
											<ComponentField
												name="cost"
												id={`inputCost${idx}`}
												label={`Cost per Unit (${bom ? bom.FG.Currency : "USD"})`}
												idx={idx}
												type="number"
												disabled={componentAlreadySubmitted(
													bom && bom.Components,
													idx,
												)}
											/>
										</div>
										<div className="flex-grow-1">
											<ExtendedCost
												idx={idx}
												currency={bom?.FG.Currency || "USD"}
											/>
										</div>
									</div>
								</div>

								<div className="mb-3">
									<OriginField componentIdx={idx} bom={bom} />
								</div>

								<div>
									<p>Is the component Originating?</p>
									<OriginatingRadioGroup idx={idx} value="Y" label="Yes" />
									<OriginatingRadioGroup idx={idx} value="N" label="No" />
								</div>
								<div className="mt-4">
									<button
										onClick={() => handleAdd(arrayHelpers)}
										type="button"
										className="btn btn-outline-primary fw-bold d-flex gap-2 align-items-center justify-content-center text-uppercase w-100"
									>
										<PiPlusSquare />
										Add component
									</button>
								</div>
							</div>
						))}
					<button
						onClick={() => handleAdd(arrayHelpers)}
						type="button"
						className="mt-5 btn btn-primary fw-bold d-flex gap-2 align-items-center justify-content-center text-uppercase w-100"
					>
						<PiPlusSquare />
						Add component
					</button>
				</div>
			)}
		</FieldArray>
	);
}

export default ComponentsFieldArray;
