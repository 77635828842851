import React from "react";
import { useSelector } from "react-redux";

import FileUploadDropzone from "../../components/FileUploadDropzone/FileUploadDropzone";
import { uploadProgressionSelector } from "../../features/fileImport/fileImportSlice";
import ChooseCurrencyModal from "./ChooseCurrencyModal";
import HeaderConfirmationModal from "./HeaderConfirmationModal";
import DownloadSampleFile from "./components/DownloadSampleFile/DownloadSampleFile";
import ProgressBar from "./components/ProgressBar/ProgressBar";
import { UPLOAD_FILE_STEP } from "./constants";
import useImportManager from "./hooks/useImportManager";
import useParseFileData from "./hooks/useParseFileData";
import useProcessUploadedFiles from "./hooks/useProcessUploadedFiles";
import useSetCurrentBOMData from "./hooks/useSetCurrentBOMData";

function FileDropzone() {
	const { handleFiles } = useProcessUploadedFiles();
	const progression = useSelector(uploadProgressionSelector);
	return (
		<div>
			<FileUploadDropzone
				handleFiles={handleFiles}
				supportedFormats={["csv", "xlsx"]}
				progression={progression}
			/>
		</div>
	);
}

export default function BOMImporter() {
	useSetCurrentBOMData();
	useImportManager();
	useParseFileData();
	return (
		<div className="container pt-5">
			<div className="row">
				<div className="col-lg-2" />
				<div className="col-lg-8">
					<div className="mb-5">
						<ProgressBar step={UPLOAD_FILE_STEP} />
					</div>
					<div className="mb-5">
						<h1 className="mb-3">Upload Your File</h1>
						<p className="mb-4">
							Upload the file containing information about the BOM you would
							like to qualify for USMCA. If you are having trouble with the
							format, click below to download a sample file you can use as an
							example.
						</p>
						<div className="d-flex">
							<DownloadSampleFile />
						</div>
					</div>
					<FileDropzone />
				</div>
				<div className="col-lg-2" />
			</div>
			<HeaderConfirmationModal />
			<ChooseCurrencyModal />
		</div>
	);
}
