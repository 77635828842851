import { Field, useFormikContext } from "formik";
import PropTypes from "prop-types";
import React from "react";

import FormError from "./FormError";

function FormikSelectField({
	name,
	label,
	options,
	helpText = null,
	allowNone = false,
}) {
	const { errors, touched } = useFormikContext();
	return (
		<>
			<label htmlFor={`select${name}`} className="form-label">
				{label}
			</label>
			<Field
				as="select"
				name={name}
				className={`form-select ${
					// biome-ignore lint/complexity/useOptionalChain: <explanation>
					touched && touched[name] && errors && errors[name] ? "is-invalid" : ""
				}`}
			>
				{allowNone && <option value="">-----------</option>}
				{options.map((option) => (
					<option
						key={`select${name}option${option.value}`}
						value={option.value}
					>
						{option.name}
					</option>
				))}
			</Field>
			{helpText && <div className="form-text">{helpText}</div>}
			{/* biome-ignore lint/complexity/useOptionalChain: <explanation> */}
			{errors && errors[name] && <FormError error={errors[name]} />}
		</>
	);
}

FormikSelectField.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	options: PropTypes.arrayOf(
		PropTypes.shape({ name: PropTypes.string, value: PropTypes.string }),
	).isRequired,
	helpText: PropTypes.string,
	allowNone: PropTypes.bool,
};

export default FormikSelectField;
