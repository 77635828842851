import { encodeURI } from "js-base64";
import PropTypes from "prop-types";
import React from "react";
import { PiPen } from "react-icons/pi";
import { useNavigate } from "react-router-dom";

import { autoTypeDisplay, isAutomotive } from "../../utils/automotive";
import { htsDisplay } from "../../utils/bom";

function FinishedGoodDetailsCard({ finishedGood, editable = false }) {
	const navigate = useNavigate();
	function handleEdit() {
		navigate(`/dashboard/bom/edit-fg/${encodeURI(finishedGood.id)}`, {
			state: {
				finishedGood: { ...finishedGood, value: finishedGood.transactionValue },
			},
		});
	}
	return (
		<div
			style={{ minHeight: "200px" }}
			className="bom-wizard__finished-good-details-card p-3 bg-dark rounded"
		>
			<div className="d-flex h-100 flex-column justify-content-between">
				<div className="mb-2">
					<div className="d-flex justify-content-between align-content-center">
						<div
							style={{ width: "max-content" }}
							className="bg-light-0 rounded-pill text-center py-1 px-3 fw-bold mb-3"
						>
							BOM Details
							{editable && (
								<button
									onClick={handleEdit}
									aria-label="Edit finished good"
									type="button"
									className="btn p-0 m-0 d-inline-block ms-1"
									style={{ transform: "translateY(-3px)" }}
								>
									<PiPen />
								</button>
							)}
						</div>
						<p className="m-0 text-light-0">
							<b>Currency:</b> {finishedGood.currency}
						</p>
					</div>

					<h4 className="text-light-0">{finishedGood.partNumber}</h4>
					<p className="text-light">{finishedGood.description}</p>
				</div>
				<div
					className="d-grid gap-2"
					style={{
						gridTemplateColumns: "repeat(3, 1fr)",
						gridTemplateRows: isAutomotive(finishedGood.hts)
							? "1fr 1fr 1fr"
							: "1fr 1fr",
						alignItems: "center",
					}}
				>
					<p className="m-0 text-light">COO | {finishedGood.origin}</p>
					<p style={{ justifySelf: "center" }} className="m-0 text-light">
						BOM ID | {finishedGood.id}
					</p>
					<p style={{ justifySelf: "end" }} className="m-0 text-light">
						HTS | {htsDisplay(finishedGood.hts)}
					</p>
					<p className="m-0 text-light">
						Transaction Value | {finishedGood.transactionValue || "-"}
					</p>
					<p style={{ justifySelf: "center" }} className="m-0 text-light">
						Net Cost | {finishedGood.netCost || "-"}
					</p>
					<p style={{ justifySelf: "end" }} className="m-0 text-light">
						Total Cost | {finishedGood.totalCost || "-"}
					</p>
					{isAutomotive(finishedGood.hts) && (
						<div
							style={{ gridColumn: "1 / -1" }}
							className="d-flex justify-content-between text-light align-items-end"
						>
							<p className="m-0">
								Automotive type | {autoTypeDisplay(finishedGood.autoType)}
							</p>
							<p className="m-0">
								Automotive timeframe | {finishedGood.autoTimeframe}
							</p>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

FinishedGoodDetailsCard.propTypes = {
	finishedGood: PropTypes.shape({
		currency: PropTypes.string,
		partNumber: PropTypes.string,
		description: PropTypes.string,
		origin: PropTypes.string,
		id: PropTypes.string,
		hts: PropTypes.string,
		transactionValue: PropTypes.string,
		netCost: PropTypes.string,
		totalCost: PropTypes.string,
	}).isRequired,
	editable: PropTypes.bool,
};

export default FinishedGoodDetailsCard;
