const CHAPTERS = ["40", "70", "83", "84", "85", "87", "90", "94"];

export function isAutomotive(fullHts) {
  if (!fullHts) {
    return false;
  }
  let _is = false;
  for (const chapter of CHAPTERS) {
    let firstTwoLetters;
    if (fullHts.slice(0, 1) === "0") {
      firstTwoLetters = fullHts.slice(1, 3);
    } else {
      firstTwoLetters = fullHts.slice(0, 2);
    }
    if (firstTwoLetters === chapter) {
      _is = true;
      break;
    }
  }
  return _is;
}

export function autoTypeDisplay(autoType) {
  if (autoType === "PASS") {
    return "Passenger";
  }
  if (autoType === "HEAVY") {
    return "Heavy";
  }
  return "Other";
}
