import { decode } from "js-base64";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

import ComponentsForm from "../../components/ComponentsForm";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { useUpdateBOMMutation } from "../../features/api/boms";
import useBom from "../../hooks/useBom";

export default function EditComponents() {
	const [bomId, setBomId] = useState(null);
	const { encodedBomId } = useParams();
	const { bom, isError } = useBom(bomId);
	const navigate = useNavigate();
	const [updateBOM, { isError: updateIsError, isSuccess }] =
		useUpdateBOMMutation();

	function handleSubmit(values, { setSubmitting }) {
		setSubmitting(true);
		const requestBody = {
			currency: bom.FG.Currency,
			fg: {
				part_number: bom.FG.Part,
				description: bom.FG.Desc,
				hts: bom.FG.Hts,
				origin: bom.FG.Origin,
				transaction_value: String(bom.FG.Value),
				net_cost: String(bom.FG.NetCost),
				total_cost: String(bom.FG.TotalCost),
				questions: [],
				auto_type: bom.FG.AutoType === "" ? null : bom.FG.AutoType,
				auto_timeframe:
					bom.FG.AutoTimeframe === "" ? null : bom.FG.AutoTimeframe,
			},
			components: values.components.map((component) => ({
				category: component.category,
				hts: component.hts,
				part_number: component.partNumber,
				description: component.description,
				origin: component.origin,
				qty: String(component.qty),
				units: component.units,
				cost: String(component.cost),
				originating: component.originating,
				questions: [],
			})),
		};

		updateBOM({ bomId, body: requestBody }).finally(() => setSubmitting(false));
	}

	useEffect(() => {
		if (encodedBomId) {
			setBomId(decode(encodedBomId));
		}
	}, [encodedBomId]);

	useEffect(() => {
		if (isError) {
			Swal.fire({
				title: "Error!",
				text: "BOM not found!",
			}).then(() => {
				navigate("/dashboard");
			}, []);
		}
	}, [isError, navigate]);

	useEffect(() => {
		if (updateIsError) {
			Swal.fire({
				title: "Error!",
				text: "Update failed. Please check the information provided.",
			});
		}
	}, [updateIsError]);

	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				title: "Success!",
				text: `BOM ${bomId} has been updated.`,
			}).then(() => {
				navigate(`/dashboard/bom/${encodedBomId}`);
			});
		}
	}, [isSuccess, bomId, encodedBomId, navigate]);

	let body;

	if (!bom) {
		body = (
			<div className="d-flex justify-content-center">
				<LoadingSpinner />
			</div>
		);
	} else {
		body = (
			<div>
				<h1 className="mb-5">Edit components | BOM {bomId}</h1>
				<ComponentsForm
					cancelUrl={`/dashboard/bom/${encodedBomId}`}
					handleSubmit={handleSubmit}
				/>
			</div>
		);
	}

	return (
		<div className="container mt-5">
			<div className="row">{body}</div>
		</div>
	);
}
