import { encodeURI } from "js-base64";
import { PiPen } from "react-icons/pi";
import { Link } from "react-router-dom";

import useBom from "../../hooks/useBom";
import { autoTypeDisplay, isAutomotive } from "../../utils/automotive";
import { htsDisplay } from "../../utils/bom";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

export default function FinishedGoodDetailsTable({ bomId }) {
	const { bom } = useBom(bomId);

	if (!bom) {
		return (
			<div className="d-flex justify-content-center align-items-center">
				<LoadingSpinner />
			</div>
		);
	}

	return (
		<table
			style={{
				verticalAlign: "middle",
				borderSpacing: 0,
				borderCollapse: "separate",
				overflow: "hidden",
			}}
			className="table border border-light-3 rounded"
		>
			<thead>
				<tr>
					<th className="bg-light-2 border-start rounded-top-start" scope="col">
						BOM ID
					</th>
					<th className="bg-light-2" scope="col">
						Part Number
					</th>
					<th className="bg-light-2" scope="col">
						Description
					</th>
					<th className="bg-light-2" scope="col">
						HTS
					</th>
					<th className="bg-light-2" scope="col">
						Transaction Value ({bom.FG.Currency})
					</th>
					<th className="bg-light-2" scope="col">
						Net Cost ({bom.FG.Currency})
					</th>
					<th className="bg-light-2" scope="col">
						Total Cost ({bom.FG.Currency})
					</th>
					{isAutomotive(bom.FG.Hts) && (
						<>
							<th className="bg-light-2" scope="col">
								Automotive Type
							</th>
							<th className="bg-light-2" scope="col">
								Automotive Timeframe
							</th>
						</>
					)}
					{bom.BOM_State === "unsubmitted" ? (
						<>
							<th className="bg-light-2" scope="col">
								Origin
							</th>
							<th className="bg-light-2 border-end rounded-top-end" scope="col">
								Edit
							</th>
						</>
					) : (
						<th className="bg-light-2 border-end rounded-top-end" scope="col">
							Origin
						</th>
					)}
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>{bom.BOMID}</td>
					<td>{bom.FG.Part}</td>
					<td>{bom.FG.Desc}</td>
					<td>{htsDisplay(bom.FG.Hts)}</td>
					<td>{bom.FG.Value}</td>
					<td>{bom.FG.NetCost}</td>
					<td>{bom.FG.TotalCost}</td>
					{isAutomotive(bom.FG.Hts) && (
						<>
							<td>{autoTypeDisplay(bom.FG.AutoType)}</td>
							<td>{bom.FG.AutoTimeframe}</td>
						</>
					)}
					<td>{bom.FG.Origin}</td>
					{bom.BOM_State === "unsubmitted" && (
						<td>
							<Link
								to={`/dashboard/bom/edit-fg/${encodeURI(bom.BOMID)}`}
								aria-label="Edit bom"
								className="btn btn-outline-primary"
								state={{
									finishedGood: {
										currency: bom.FG.Currency,
										partNumber: bom.FG.Part,
										description: bom.FG.Desc,
										hts: bom.FG.Hts,
										value: bom.FG.Value,
										netCost: bom.FG.NetCost,
										totalCost: bom.FG.TotalCost,
										coo: bom.FG.Origin,
									},
								}}
							>
								<PiPen />
							</Link>
						</td>
					)}
				</tr>
			</tbody>
		</table>
	);
}
