import { decode } from "js-base64";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

import FinishedGoodForm from "../../components/FinishedGoodForm/FinishedGoodForm";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { useGetBomQuery, useUpdateBOMMutation } from "../../features/api/boms";

export default function EditFinishedGood() {
	const [bomId, setBomId] = useState(null);
	const { encodedBomId } = useParams();
	const { data: bom, isError } = useGetBomQuery(bomId, { skip: !bomId });
	const [updateBOM, { isError: updateIsError, isSuccess }] =
		useUpdateBOMMutation();
	const navigate = useNavigate();

	function handleSubmit(values, { setSubmitting }) {
		setSubmitting(true);
		const requestBody = {
			currency: bom.FG.Currency,
			fg: {
				part_number: values.partNumber,
				description: values.description,
				hts: values.hts,
				origin: values.coo,
				transaction_value: values.value,
				net_cost: values.netCost,
				total_cost: values.totalCost,
				questions: [],
				auto_type: values.autoType === "" ? null : fg.autoType,
				auto_timeframe: values.autoTimeframe === "" ? null : fg.autoTimeframe,
			},
			components: bom.Components.map((component) => ({
				category: component.Category,
				hts: component.Hts,
				part_number: component.Part,
				description: component.Desc,
				origin: component.Origin,
				qty: component.Qty,
				units: component.Unit,
				cost: component.Cost,
				originating: component.Originating === "Y",
				questions: [],
			})),
		};
		updateBOM({ bomId, body: requestBody }).then(() => setSubmitting(false));
	}

	useEffect(() => {
		if (encodedBomId) {
			setBomId(decode(encodedBomId));
		} else {
			setBomId(null);
		}

		return () => {
			setBomId(null);
		};
	}, [encodedBomId]);

	useEffect(() => {
		if (isError) {
			Swal.fire({ title: "Error!", text: "BOM not found!" }).then(() => {
				navigate("/dashboard");
			});
		}
	}, [isError, navigate]);

	useEffect(() => {
		if (updateIsError) {
			Swal.fire({
				title: "Error!",
				text: "Failed to update. Please check the information provided.",
			});
		}
	}, [updateIsError]);

	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				title: "Success!",
				text: `BOM ${bomId} has been updated!`,
			}).then(() => {
				navigate(`/dashboard/bom/${encodedBomId}`, { replace: true });
			});
		}
	}, [isSuccess, navigate, encodedBomId, bomId]);

	let body;

	if (!bom) {
		body = (
			<div className="d-flex justify-content-center">
				<LoadingSpinner />
			</div>
		);
	}
	body = (
		<div>
			<h1 className="mb-5">Edit Finished Good | BOM {bomId}</h1>
			<FinishedGoodForm
				handleSubmit={handleSubmit}
				cancelUrl={`/dashboard/bom/${encodedBomId}`}
			/>
		</div>
	);

	return (
		<div className="container mt-5">
			<div className="row">{body}</div>
		</div>
	);
}
